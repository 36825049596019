import React, { ReactNode } from 'react';
import { graphql } from 'gatsby';
import VizSensor from 'react-visibility-sensor';
import { Helmet } from 'react-helmet';

import Layout from 'components/layouts/main/Layout';
import FirstScreen from 'components/products/FirstScreen';
import SecondScreen from 'components/products/SecondScreen';
import ThirdScreen from 'components/products/ThirdScreen';
import FourthScreen from 'components/products/FourthScreen';
import PickBar from 'components/shared/pick-bar/PickBar';
import productImg from 'images/CLICK_RESTORE_61020-01.png';

export default class RestorePage extends React.Component<any> {
  state = {
    productsIsVisible: false,
  };

  render(): ReactNode {
    const { data } = this.props;

    return (
      <Layout location={this.props.path} setNavAbsolute={this.state?.productsIsVisible}>
        <Helmet>
          <title>Restore Effect</title>
        </Helmet>
        <FirstScreen
          activeProduct="restore"
          productImage={data.restoreProduct.childImageSharp.fluid}
        ></FirstScreen>

        <SecondScreen
          activeProduct="restore"
          backgroundPicture={data.theme.childImageSharp.fluid}
          shadowTriangle={data.shadowTriangle.childImageSharp.fluid}
          productImage={data.restoreProduct.childImageSharp.fluid}
        ></SecondScreen>

        <ThirdScreen
          productImg={productImg}
          activeProduct="restore"
          recycleLogo={data.recycleLogo.childImageSharp.fluid}
        ></ThirdScreen>

        <FourthScreen
          currentPage="restore"
          backgroundImg={data.bgImage.childImageSharp.fluid}
        ></FourthScreen>

        <VizSensor
          partialVisibility
          scrollCheck
          scrollThrottle={10}
          onChange={(isVisible) => {
            this.setState({ productsIsVisible: isVisible });
          }}
        >
          <PickBar currentPage="restore"></PickBar>
        </VizSensor>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    bgImage: file(relativePath: { eq: "bg-restore.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    restoreProduct: file(relativePath: { eq: "restore-product-image.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shadowTriangle: file(relativePath: { eq: "triangle-shadow-restore.png" }) {
      childImageSharp {
        fluid(maxHeight: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theme: file(relativePath: { eq: "RESTORE-Page.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # productImg: file(relativePath: { eq: "Click_Restore-Packaging-Render_x1.jpg" }) {
    #   childImageSharp {
    #     fluid(maxHeight: 1000, quality: 100) {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
    recycleLogo: file(relativePath: { eq: "Icon-Recycle-RestoreGreen.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
